<template>
  <el-container class="friend-page">
    <el-aside width="380px" class="friend-list-box">
      <div class="friend-list-header">
        <el-input class="search-text" placeholder="搜索" v-model="searchText">
          <i class="el-icon-search el-input__icon" slot="prefix"> </i>
        </el-input>
      </div>
      <div class="dep-select">
        <el-cascader
          style="width: 345px"
          placeholder="选择部门"
          v-model="currentDepId"
          :options="depList"
          :show-all-levels="false"
          :clearable="true"
          @change="deptChange"
        >
        </el-cascader>
      </div>
      <el-scrollbar class="friend-list-items">
        <div
          v-for="(friend, index) in $store.state.friendStore.friends"
          :key="index"
        >
          <friend-item
            v-show="friend.nickName.includes(searchText)"
            :index="index"
            :active="friend === $store.state.friendStore.activeFriend"
            @chat="onSendMessage(friend)"
            @delete="onDelItem(friend, index)"
            @click.native="onActiveItem(friend, index)"
          >
          </friend-item>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-container class="friend-box">
      <div class="friend-header" v-show="userInfo.id">
        {{ userInfo.nickName }}
      </div>
      <div v-show="userInfo.id">
        <div class="friend-detail">
          <head-image
            radius="10%"
            :size="200"
            :name="userInfo.nickName"
            :url="userInfo.headImage"
            @click.native="showFullImage()"
          ></head-image>
          <div>
            <div class="info-item">
              <el-descriptions title="好友信息" class="description" :column="1">
                <el-descriptions-item label="昵称"
                  >{{ userInfo.nickName }}
                </el-descriptions-item>
                <el-descriptions-item label="性别">{{
                  userInfo.sex == 0 ? "男" : "女"
                }}</el-descriptions-item>
                <!-- <el-descriptions-item label="部门">{{
                  userInfo.deptName || ""
                }}</el-descriptions-item> -->
              </el-descriptions>
            </div>
            <div class="frient-btn-group">
              <el-button
                v-show="isFriend"
                icon="el-icon-position"
                type="primary"
                @click="onSendMessage(userInfo)"
                >发消息</el-button
              >
              <el-button
                v-show="!isFriend"
                icon="el-icon-plus"
                type="primary"
                @click="onAddFriend(userInfo)"
                >加为好友</el-button
              >
              <!-- <el-button
                v-show="isFriend"
                icon="el-icon-delete"
                type="danger"
                @click="onDelItem(userInfo, activeIdx)"
                >删除好友</el-button
              > -->
            </div>
          </div>
        </div>
        <el-divider content-position="center"></el-divider>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import FriendItem from "../components/friend/FriendItem.vue";
import AddFriend from "../components/friend/AddFriend.vue";
import HeadImage from "../components/common/HeadImage.vue";
import axios from "axios";

export default {
  name: "friend",
  components: {
    FriendItem,
    AddFriend,
    HeadImage,
  },
  data() {
    return {
      searchText: "",
      showAddFriend: false,
      activeIdx: -1,
      userInfo: {},
      depList: [],
      currentDepId: [],
    };
  },
  methods: {
    onActiveItem(friend, idx) {
      this.$store.commit("activeFriend", idx);
      this.activeIdx = idx;
      this.loadUserInfo(friend, idx);
    },
    onDelItem(friend, idx) {
      this.$confirm(
        `确认删除'${friend.nickName}',并清空聊天记录吗?`,
        "确认解除?",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$http({
          url: `/friend/delete/${friend.id}`,
          method: "delete",
        }).then((data) => {
          this.$message.success("删除好友成功");
          this.$store.commit("removeFriend", idx);
          this.$store.commit("removePrivateChat", friend.id);
        });
      });
    },
    onAddFriend(user) {
      this.$http({
        url: "/friend/add",
        method: "post",
        params: {
          friendId: user.id,
        },
      }).then((data) => {
        this.$message.success("添加成功，对方已成为您的好友");
        let friend = {
          id: user.id,
          nickName: user.nickName,
          headImage: user.headImage,
          online: user.online,
        };
        this.$store.commit("addFriend", friend);
      });
    },
    onSendMessage(user) {
      let chat = {
        type: "PRIVATE",
        targetId: user.id,
        showName: user.nickName,
        headImage: user.headImageThumb,
      };
      this.$store.commit("openChat", chat);
      this.$store.commit("activeChat", 0);
      this.$router.push("/home/chat");
    },
    showFullImage() {
      if (this.userInfo.headImage) {
        this.$store.commit("showFullImageBox", this.userInfo.headImage);
      }
    },
    updateFriendInfo(friend, user, index) {
      // store的数据不能直接修改，深拷贝一份store的数据
      friend = JSON.parse(JSON.stringify(friend));
      friend.headImage = user.headImageThumb;
      friend.nickName = user.nickName;
      this.$http({
        url: "/friend/update",
        method: "put",
        data: friend,
      }).then(() => {
        this.$store.commit("updateFriend", friend);
        this.$store.commit("updateChatFromFriend", user);
      });
    },
    loadUserInfo(friend, index) {
      this.$http({
        url: `/user/find/${friend.id}`,
        method: "get",
      }).then((user) => {
        this.userInfo = user;
        // 如果发现好友的头像和昵称改了，进行更新
        if (
          user.headImageThumb != friend.headImage ||
          user.nickName != friend.nickName
        ) {
          // this.updateFriendInfo(friend, user, index);
        }
      });
    },
    getDepList() {
      let authorization = sessionStorage.getItem("authorization");
      axios({
        method: "get",
        url: process.env.VUE_APP_ZN_URL + "/admin-api/system/dept/simple-list",
        headers: {
          authorization: authorization,
        },
      })
        .then((res) => {
          let list = res.data.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
              ...item,
            };
          });
          this.depList = [];
          this.depList.push(...this.handleTree(list));
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handleTree(data, id, parentId, children) {
      if (!Array.isArray(data)) {
        console.warn("data must be an array");
        return [];
      }
      const config = {
        id: id || "id",
        parentId: parentId || "parentId",
        childrenList: children || "children",
      };

      const childrenListMap = {};
      const nodeIds = {};
      const tree = [];

      for (const d of data) {
        const parentId = d[config.parentId];
        if (childrenListMap[parentId] == null) {
          childrenListMap[parentId] = [];
        }
        nodeIds[d[config.id]] = d;
        childrenListMap[parentId].push(d);
      }

      for (const d of data) {
        const parentId = d[config.parentId];
        if (nodeIds[parentId] == null) {
          tree.push(d);
        }
      }

      for (const t of tree) {
        adaptToChildrenList(t);
      }

      function adaptToChildrenList(o) {
        if (childrenListMap[o[config.id]] !== null) {
          o[config.childrenList] = childrenListMap[o[config.id]];
        }
        if (o[config.childrenList]) {
          for (const c of o[config.childrenList]) {
            adaptToChildrenList(c);
          }
        }
      }
      return tree;
    },
    deptChange(e) {
      let id = e[e.length - 1];
      this.$store
        .dispatch("loadFriend", id)
        .then((res) => {
          this.$store.commit("updateCurrentDeptIds", this.currentDepId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.currentDepId = this.$store.state.friendStore.currentDeptIds;
    this.getDepList();
  },
  computed: {
    friendStore() {
      return this.$store.state.friendStore;
    },
    isFriend() {
      return this.friendStore.friends.find((f) => f.id == this.userInfo.id);
    },
  },
};
</script>

<style lang="scss" >
.friend-page {
  .friend-list-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #e7f1fc;
    padding: 14px 18px;

    .friend-list-header {
      height: 50px;
      display: flex;
      align-items: center;

      .el-input__inner {
        border-radius: 4px !important;
        background-color: #fff;
        border: none !important;
      }

      .add-btn {
        padding: 5px !important;
        margin: 5px;
        font-size: 20px;
        color: #587ff0;
        border: #587ff0 1px solid;
        background-color: #f0f8ff;
        border-radius: 50%;
      }
    }

    .friend-list-items {
      flex: 1;
      margin-top: 10px;
    }
  }

  .friend-box {
    background: #fff;
    display: flex;
    flex-direction: column;

    .friend-header {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      background-color: white;
      border-bottom: 1px #eeeeee solid;
      border-top: 1px #eeeeee solid;
    }

    .friend-detail {
      display: flex;
      padding: 50px 80px 20px 80px;
      text-align: center;

      .info-item {
        margin-left: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        border: 1px #ddd solid;
      }

      .description {
        padding: 20px 20px 0px 20px;
      }
    }

    .frient-btn-group {
      text-align: left !important;
      padding: 20px;
    }
  }

  .dep-select {
    width: 100%;
    padding: 4px 0 2px 0;
    text-align: left;
    font-size: 16px;
    color: #505050;

    .el-input__inner {
      background-color: #e7f1fc !important;
      border: none !important;
      padding-left: 0px !important;
    }

    .el-input__suffix {
      color: #505050;
      font-size: 18px;
    }

    .el-icon-arrow-down {
      color: #505050;
      font-size: 18px;
    }
  }
}
</style>